import { useState } from '#app'
import { useRuntimeConfig } from '#imports'
import { computed, onMounted, watchEffect } from 'vue'
import type { LocationQuery } from 'vue-router'

import type { Price } from '@backmarket/http-api'
import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import {
  deleteSwap,
  getSwap,
  postSwap,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { calculateReduction } from '@backmarket/utils/math/calculateReduction'

import {
  buybackOfferToLegacyOffer,
  legacyOfferToBuybackOffer,
} from '../../adapters/buybackOffer/buybackOffer'

export function useBuybackOffer() {
  const { defaultCurrency } = useMarketplace().market
  const { KILL_BUYBACK_CART_SWAP } = useRuntimeConfig().public

  const i18n = useI18n()

  const defaultBuybackOfferPrice: Price = {
    amount: '140',
    currency: defaultCurrency,
  }

  const freePrice: Price = {
    amount: '0',
    currency: defaultCurrency,
  }

  const fetchedFromCart = useState<boolean>(
    'buyback-offer-from-cart',
    () => false,
  )

  const buybackOffer = useState<GetOfferV1Response | null>(
    'buyback-offer',
    () => null,
  )
  const buybackOfferPayload = useState<Record<string, unknown> | null>(
    'buyback-offer-payload',
    () => null,
  )

  const { data: swapOfferFromCart, execute } = useHttpFetch(getSwap, {
    immediate: false,
  })

  const hasBuybackOffer = computed(() => buybackOffer.value !== null)

  function setBuybackOffer(
    offer: GetOfferV1Response,
    payload: LocationQuery | Record<string, unknown>,
  ) {
    buybackOffer.value = offer
    buybackOfferPayload.value = payload
  }

  function deleteBuybackOffer() {
    void $httpFetch(deleteSwap)

    buybackOffer.value = null
  }

  function resetBuybackOffer() {
    buybackOffer.value = null
  }

  function getDiscountedPrice(initialPrice: Price | null) {
    if (!initialPrice) {
      return freePrice
    }
    const finalPrice = calculateReduction(
      initialPrice,
      buybackOffer.value?.listing.price ?? defaultBuybackOfferPrice,
    )

    return finalPrice && parseFloat(finalPrice.amount) >= 0
      ? finalPrice
      : freePrice
  }

  async function saveBuybackOffer(
    offer: GetOfferV1Response,
    payload: LocationQuery | Record<string, unknown>,
  ) {
    await $httpFetch(postSwap, {
      body: buybackOfferToLegacyOffer(
        offer,
        payload,
        i18n.currencySign,
      ) as unknown as Record<string, unknown>,
    })

    setBuybackOffer(offer, payload)
  }

  function getDiscountedPriceForProductCard(initialPrice: Price) {
    if (initialPrice && buybackOffer.value?.listing.price) {
      const finalPrice = calculateReduction(
        initialPrice,
        buybackOffer.value?.listing.price,
      )

      return finalPrice && parseFloat(finalPrice.amount) >= 0
        ? finalPrice
        : freePrice
    }

    return null
  }

  watchEffect(() => {
    if (swapOfferFromCart.value) {
      setBuybackOffer(
        legacyOfferToBuybackOffer(swapOfferFromCart.value),
        swapOfferFromCart.value.diagnostic_payload,
      )
    }
  })

  onMounted(() => {
    if (!KILL_BUYBACK_CART_SWAP && !fetchedFromCart.value) {
      void execute()
      fetchedFromCart.value = true
    }
  })

  return {
    buybackOffer,
    buybackOfferPayload,
    hasBuybackOffer,
    saveBuybackOffer,
    getDiscountedPrice,
    getDiscountedPriceForProductCard,
    deleteBuybackOffer,
    resetBuybackOffer,
  }
}
