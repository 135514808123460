import type { Price } from '@backmarket/http-api'

export function calculateReduction(initialPrice: Price, discountPrice: Price) {
  if (!initialPrice?.amount || !discountPrice?.amount) {
    return null
  }
  const initialPriceValue = parseFloat(initialPrice.amount)
  const discountPriceValue = parseFloat(discountPrice.amount)

  if (Number.isNaN(initialPriceValue) || Number.isNaN(discountPriceValue)) {
    return null
  }

  return {
    amount: (initialPriceValue - discountPriceValue).toString(),
    currency: initialPrice.currency,
  }
}
